<template>
  <span>Loading...</span>
</template>

<script>
export default {
  created() {
    let platform = this.$route.query.platform
    this.$store.dispatch('cordova/setPlatform', platform)
    this.$router.replace({ name: 'auth.login' })
  },
}
</script>
